var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Add Domain ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"placeholder":"example.com"},model:{value:(_vm.newDomain),callback:function ($$v) {_vm.newDomain=$$v},expression:"newDomain"}}),_c('v-btn',{on:{"click":function($event){return _vm.addDomain()}}},[_vm._v("Add Domain")]),_c('br'),_c('br')],1)],1)],1),_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Domains "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.domains,"search":_vm.search,"single-select":true,"show-select":""},scopedSlots:_vm._u([{key:"item.mx",fn:function(ref){
var item = ref.item;
return [(item.detail.RecordChecked)?_c('span',[(item.detail.MXRecordCheck)?_c('v-chip',{staticStyle:{"width":"100px"},attrs:{"color":"green"}},[_vm._v("Yes")]):_vm._e(),(!item.detail.MXRecordCheck)?_c('v-chip',{staticStyle:{"width":"100px"},attrs:{"color":"red"}},[_vm._v("No")]):_vm._e()],1):_vm._e(),(!item.detail.RecordChecked)?_c('v-chip',{staticStyle:{"width":"100px"}},[_vm._v("Unknown")]):_vm._e()]}},{key:"item.spf",fn:function(ref){
var item = ref.item;
return [(item.detail.RecordChecked)?_c('span',[(item.detail.SPFRecordCheck)?_c('v-chip',{staticStyle:{"width":"100px"},attrs:{"color":"green"}},[_vm._v("Yes")]):_vm._e(),(!item.detail.SPFRecordCheck)?_c('v-chip',{staticStyle:{"width":"100px"},attrs:{"color":"red"}},[_vm._v("No")]):_vm._e()],1):_vm._e(),(!item.detail.RecordChecked)?_c('v-chip',{staticStyle:{"width":"100px"}},[_vm._v("Unknown")]):_vm._e()]}},{key:"item.dmarc",fn:function(ref){
var item = ref.item;
return [(item.detail.RecordChecked)?_c('span',[(item.detail.DMARCRecordCheck)?_c('v-chip',{staticStyle:{"width":"100px"},attrs:{"color":"green"}},[_vm._v("Yes")]):_vm._e(),(!item.detail.DMARCRecordCheck)?_c('v-chip',{staticStyle:{"width":"100px"},attrs:{"color":"red"}},[_vm._v("No")]):_vm._e()],1):_vm._e(),(!item.detail.RecordChecked)?_c('v-chip',{staticStyle:{"width":"100px"}},[_vm._v("Unknown")]):_vm._e()]}},{key:"item.dkmi",fn:function(ref){
var item = ref.item;
return [(item.detail.RecordChecked)?_c('span',[(item.detail.DKIMCheck)?_c('v-chip',{staticStyle:{"width":"100px"},attrs:{"color":"green"}},[_vm._v("Yes")]):_vm._e(),(!item.detail.DKIMCheck)?_c('v-chip',{staticStyle:{"width":"100px"},attrs:{"color":"red"}},[_vm._v("No")]):_vm._e()],1):_vm._e(),(!item.detail.RecordChecked)?_c('v-chip',{staticStyle:{"width":"100px"}},[_vm._v("Unknown")]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.selected[0])?_c('v-btn',{on:{"click":function($event){return _vm.removeDomain()}}},[_vm._v("Remove selected Domain")]):_vm._e(),_c('br'),_c('br')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }